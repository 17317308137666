@import "../../styles/variables";
@import "../../styles/typo";


.sidebar {
  color: var(--text);
  margin-inline-start: 50px;
  position: relative;
  display: contents;
  &-header {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 27px 0;
    & > * {
      height: 60px;
    }
  }
  &-back {
    margin-inline-end: 50px;
  }
  &-description {
    padding: 36px 0 0;
    font-size: 16px;
    text-align: center;
  }
  &-component {
    //display: flex;
    padding: 25px 0;
    height: 100%;
  }
  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin: auto;
  }
  &-trigger {
    display: block;
    position: relative;
    z-index: 1;
  }
  &-content {
    @extend.transpernt_over_flow;
    position: fixed;
    left: 100%;
    top: 0;
    height: 100vh;
    max-width: 700px;
    width: 100%;
    //padding: 20px 100px 50px 80px;
    padding: 20px 40px;
    box-sizing: border-box;
    background: var(--background);
    z-index: 5;
    border-left: 1px solid $light-gray;
    overflow-y: scroll;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    
    &.open {
      display: block;
      transform: translateX(calc(-100%)) ;
      z-index: 6;
      @media (max-width: $mobile), (min-width: $mobile) and (max-width: $tablet) {
        transform: translateX(-100%);
        max-width: $tablet;
      }
    }
    &-main {
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(160px);
      display: flex;
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      padding: 20px 30px;
      max-width: none;
    }
    &.overflowHidden {
      overflow: hidden;
    }
  }

  &-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: $tablet) {
      padding: 0 50px;
    }
    @media (max-width: $mobile) {
      padding: 0 30px;
    }
  }

  .close-button{
    position: absolute;
    right: 10px;
  }
}

.dark {
  .public-menu-content-main {
    background: var(--background);
  }
}
// .rtl {
//   .public-menu-content {
//     left: auto;
//     right: 100%;
//     direction: rtl;
//     &.open {
//       transform: translateX(calc(100% - 30px));
//       @media (max-width: $mobile) {
//         transform: translateX(100%);
//       }
//     }
//   }
// }
