.auto-save {
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    background: #000000;
    border-radius: 8px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    z-index: 5;
    &.isVisible{
        display: flex;
        transition: 0.6;
        visibility: visible;
    }
    &-text {
        //width: 95px;
        //height: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        flex: none;
        order: 1;
        flex-grow: 0;
    }
    &-icon {
        position: relative;
        top: 2px;
    }
}
