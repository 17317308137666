@import "../../styles/variables";
.chatbot-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
}
.page-header-left {
    .chatbot-header-left {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        right: 20px;
        color: var(--text);
        &-toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            &-title {
                font-size: 16px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 200px;
                color: var(--text);
                direction: ltr !important;
                background: inherit;
                // input {
                //     border: 0;
                //     background: transparent;
                //     text-align: start;
                //     &.rtl {
                //         text-align: end;
                //     }
                // }
            }
        }
        &-line {
            margin-right: 10px;
            border-right: 1px solid $light-gray;
            height: 30px;
        }
        & > * {
            margin-right: 10px;
        }
    }
}


.builder-style-editor{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    height: 50vh;
}

