@import '/src/styles/variables';

.logoChannel {
  width: $logo-channel-size;
  height: $logo-channel-size;
  @media(max-width: $mobile){
    width: $logo-channel-size-mobile;
    height: $logo-channel-size-mobile;
  }
  display: inline-block;
  vertical-align: top;
  background-image: url(../../assets/images/logo-channel.svg);
  background-position: var(--bg-position);
  &-mask {mask-image: url(../../assets/images/logo-channel.svg); mask-position: var(--bg-position); background: var(--icon-color);}
  &-white {background: $white;}
  &-black {background: $black;}
  &-primary {background: $primary;}
  &-gray {background: $gray;}


  &-maytapi {
    --bg-position: 0 0;
    @media(max-width: $mobile) {
      --bg-position: 0px -70px;
    }
  }
  &-whatsapp {
    --bg-position: -70px 0;
    @media(max-width: $mobile) {
      --bg-position: -30px -70px;
    }
  }
  &-tyntec {
    --bg-position: -210px 0;
    @media(max-width: $mobile) {
      --bg-position: -60px -70px;
    }
  }

  &-officely{
    --bg-position: -140px 0;
    @media(max-width: $mobile) {
      --bg-position: -90px -70px;
    }
  }
  &-meta{
    --bg-position: -280px 0;
    @media(max-width: $mobile) {
      --bg-position: -120px -70px;
    }
  }



  &-maytapi-small{@extend .small; --bg-position: 0px -70px; }
  &-officely-small{@extend .small; --bg-position: -90px -70px; }
  &-meta-small{@extend .small; --bg-position: -120px -70px; }
  &-zendesk-small{@extend .small; --bg-position: -150px -70px; }
  &-whatsapp-small{@extend .small; --bg-position: -30px -70px; }


}


.small {width: 30px; height: 30px;}



