@import "../../styles/variables";
@import "../../styles/typo";

.builders {
    @extend.transpernt_over_flow;
}

.chat-builder {
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: grab;
}
.node {
    background: border-box;
}

.contextMenu {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    bottom: 100%;
    z-index: 999;
    width: 150px;
    &.dir {
        bottom: unset;
    }
}

.actions {
    margin-bottom: 10px;
    border: none;
    outline: none;
    border-radius: 20px;
    box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.07);
}

.sidebar-chat-builder {
    background: var(--background);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    max-width: 345px;
    width: 100%;
    height: 98%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 0.5px solid lightgray;
    .list {
        margin: 10px 0;
        &-title {
            margin-inline-start: 10px;
            color: #9b9b9b;
            font-size: 13px;
        }
        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #f8f8f8;
            position: relative;
            border-radius: 12px;
            width: 149px;
            height: 42px;
            margin: 10px;
            line-height: 18px;
            border: var(--border-light);
            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
            }
        }
        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &.limited {
        width: 70px;
        .list-item {
            background: none;
            box-shadow: none;
            margin: 15px 0;
            border: 0;
        }
    }



    &.previewMode {
        .sidebar-chat-builder-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0.5rem 0.5rem 1rem;
            .whatsaap-preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 3px 8px;
                gap: 10px;
                width: 140px;
                border: var(--border-light);
                border-radius: 6px;
                cursor: pointer;
                &-title {
                    font-size: 0.7em;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #5f6368;
                    font-weight: 500;
                }
                &-icon {
                    display: flex;
                    align-items: center;
                }
                &:hover {
                    opacity: 0.8;
                }
            }
            .close-preview {
                .button {
                    --border-color: rgba(196, 196, 196, 0.4);
                }
            }
        }
        .sidebar-chat-builder-content {
            height: 90%;
            overflow: hidden;
        }
        .widget {
            border-radius: 6px;
            margin: 0 10px;
            display: flex;
            flex-direction: column;
        }
        &.limited {
            display: none;
        }
    }
    &.nodeListActions {
        .minimize {
            position: absolute;
            transform: translateX(315px) translateY(-2px);
            &.limited {
                transform: translateX(40px);
            }
        }
        .sidebar-chat-builder-content {
            @extend.transpernt_over_flow;
            height: auto;
        }
    }
}


