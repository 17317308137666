.filter-group {
    // border: var(--border-light);
    // border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
    gap: 10px;
    .form-group {
        margin-bottom: 0;
        input {
            background-color: white;
            border: var(--border-light);
        }
    }
    .chakra-popover__popper[style*="visibility: hidden"] {
        display: none !important;
    }
    &-title {
        color: #9b9b9b;
        &-remove{
            position: relative;
            top: -2px;
        }
        
    }
    &-logical {
        left: 0;
        right: 0;
        transform: translate(100%);
        height: 50px !important;
        width: 115px !important;
        border-color: #d8d8d8 !important;
        margin: 30px 0;

        .chakra-popover__popper {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

    &-operator {
        height: 58px !important;
    }
    &-add{
        display: flex;
        justify-content: center;
    }
    .field-input{
        display: flex;
        flex-direction: column;
        gap: 5px;
        label{
            align-self: flex-end;
        }
    }
}

