@import "../../styles/variables";
@import "../../styles/typo";

.live-page {
    --padding: 0 15px;
    display: flex;
    overflow-x: hidden; 
    overflow-y: auto; 
    max-width: 100vw; 
    flex-wrap: nowrap; 
    .widget {
        border-radius: 15px;
        &-content {
            margin: 0 10px;
        }
        &-footer {
            display: none;
        }
    }
    &-sidebar {
        display: flex;
        flex-direction: column;
        flex: 3;
        padding: var(--padding);
        gap: 10px;
        max-width: 400px;    
        .filter {
            display: flex;
        }
        .search {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: var(--border-light);
            background: #fff;
            width: 100%;
            &-input {
                max-width: unset;
                width: 100%;
            }
        }

        .contacts {
            @extend.transpernt_over_flow;
            overflow-y: auto;
            gap: 25px;
            display: flex;
            flex-wrap: wrap;
            .contact {
                align-items: flex-start;
                background-color: #ffffff;
                border: 1px solid;
                border: var(--border-light);
                border-radius: 10px;
                display: inline-flex;
                flex-direction: column;
                gap: 10px;
                padding: 8px 12px;
                width: 100%;
                cursor: pointer;

                &-item {
                    align-items: center;
                    display: flex;
                    gap: 16px;
                    height: 48px;
                    width: 100%;
                    &-texts {
                        align-items: flex-start;
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        flex-grow: 1;
                        gap: 8px;
                        position: relative;
                        overflow: hidden;
                        text-align: left;

                        &-name {
                            align-self: stretch;
                            color: var(--dark-01);
                            font-family: "Plus Jakarta Sans-Medium", Helvetica;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 24px;
                            margin-top: -1px;
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 80%;
                        }

                        &-message {
                            align-self: stretch;
                            color: var(--dark-01);
                            font-family: "Plus Jakarta Sans-Regular", Helvetica;
                            font-size: 12px;
                            font-weight: 400;
                            letter-spacing: 0;
                            line-height: 16px;
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    &-time {
                        align-items: flex-end;
                        display: inline-flex;
                        flex: 0 0 auto;
                        flex-direction: column;
                        gap: 8px;
                        justify-content: center;
                        position: relative;

                        &-text {
                            color: #829c99;
                            font-family: "Plus Jakarta Sans-Regular", Helvetica;
                            font-size: 10px;
                            font-weight: 400;
                            letter-spacing: 0;
                            line-height: 16px;
                            margin-top: -1px;
                            position: relative;
                            text-align: right;
                            white-space: nowrap;
                            width: fit-content;
                        }
                    }
                }
                &.active {
                    border-color: $primary;
                }
                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
                }
            }
        }
    }

    &-conversation-panel {
        padding: var(--padding);
        flex: 7;
        overflow-x: auto; /* Allow horizontal scrolling only within this panel if needed */
        min-width: 0; /* Prevent the flex item from exceeding its parent's width */
        max-width: calc(85vw - 400px - 30px); /* Adjust width considering the sidebar and padding */
        word-wrap: break-word; /* Ensure long words break to fit within the container */
    }
}

.datetime {
    background-color: white;
    text-align: center;
    max-width: 100px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin: 0 auto;
    color: gray;
    border: var(--border-light);
    &.margin {
        margin: 30px auto;
    }
}

@media (max-width: $mobile) {
    .live-page {
        height: 100%;
        max-height: unset;
        &-sidebar {
            width: 100%;
            .contacts {
                overflow-y: unset;
                flex-wrap: wrap;
                .contact {
                    margin: 0;
                }
            }
            &.none {
                display: none;
            }
        }
        .conversation-panel {
            display: none;
        }

    }
    .account-menu-trigger, .account-logo, .page-header{
        &.none{
            display: none !important;
        }
    }
}

.deatils-conversation{
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 24px;
    font-weight: 600;
    // display: flex !important;
    // gap: 10px;
    & > *{
        margin-right: 10px;
    }
}

.close-conversation{
    position: absolute;
    top: 30px;
    right: 30px;
}



