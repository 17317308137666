@import "../../styles/variables";
@import "../../styles/typo";

.ingest-agent {
    margin: 35pt 32pt 0;
    height: 90%;
    --background-content:blue;
}

.layout {
    display: flex;
    flex-direction: row;
    text-align: initial;
    position: relative;
    height: 100%;
    &-content {
        flex-direction: column; /* This keeps layout-content as a column */
        flex-shrink: 0;
        width: 250px;
        display: flex; /* Ensures flex-direction is applied */
        flex: 0 0 25%;
    }
}



.step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: black;
    text-align: center;
    line-height: 30px;
    margin-right: 25px;
    z-index: 1;
    // cursor: not-allowed;
    // &.clickable {
    //     cursor: pointer;
    // }
    &.active {
        background-color: $primary;
        color: white;
    }
    &.done {
        background-color: #4ade80 !important;
    }
    &-title {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }
    &-container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 300px;
    }
    &-line {
        display: flex;
        align-items: center;
    }
}

.sub {
    &-stages {
        margin-left: 50pt;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &-stage {
        color: #737373;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 5pt;
        gap: 5px;
        display: flex;
        padding-left: 10px;
        position: relative;
        &::before {
            content: "•"; 
            position: absolute;
            left: -10px;
            top: 0;
            font-size: 35px; 
            color:$gray; 
        }
        &.active::before{
            color: $primary;
        }
        &.done::before{
            color: #4ade80;
        }
    }
}

.main-content {
    //padding: 20pt 40pt;
    display: flex;
    gap: 30px;
    flex: 0 0 70%;
    width: 0;
    height: 100%;
    //max-height: 50vh;
}

.gray-bubble {
    position: absolute;
    top: -15px;
    left: -5px;
    width: 40px;
    background: rgba(217, 217, 217, 0.44);
    border-radius: 110px;
    &.length-3{
        height: 300pt;
    }
    &.length-5{
        height: 380pt;

    }
}

// #uppyDashboard {
//     max-width: 100%;
// }
// .uppy-Dashboard-container {
//     width: 100% !important;
// }
// .uppy-Dashboard-inner{
//     @media (max-width:$mobile) {
//         width: 250px;
//     }
// }





@media (max-width:$mobile) {
    .ingest-agent{
        margin: 0;
    }

    .gray-bubble{
        display: none;
    }
    .layout{
        display: block;
    }
    .layout-content{
        display: none;
    }
    .main-content{
        padding: 10px;
        flex-direction: column;
        width: unset;
        //max-width: 50%;
        .title{
            color: $primary;
            text-align: center;
        }
    }


}