@import "src/styles/variables";
@import "src/styles/typo";

.account {
  @extend.transpernt_over_flow;
  //height: calc(var(--doc-height, 1vh) * 100);
  height: 100%;
  width: 100%;
  display: flex;
  background: var(--background-content);
  &.ingestAgent{
    background:var(--background-content-team)
  }
  .billing{
    background:#fdf7f5;
  }
  &-mobile {
    @media (max-width: $mobile) {
      display: block;
    }
  }
  &-menu {
    overflow: hidden;
    overflow-y: scroll;
    width: 300px;
    height: 100%;
    @media (max-width: $mobile) {
      width: 100%;
    }
    &-terms {
      position: relative;
      z-index: 10;
    }
    &-scroll {
      width: 266px;
      overflow: hidden;
      position: relative;
      height: 100%;
      flex: 0 0 auto;
      &::before {
        width: 256px;
        background: $white;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: 0;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
      @media (max-width: $mobile) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
        pointer-events: none;
        z-index: 10;
        &.open {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
    &-wrapper {
      width: 256px;
      height: 100%;
      display: flex;
      padding: 40px 40px 0; 
      box-sizing: border-box;
      flex-direction: column;
      @media (max-width: $mobile) {
        width: 100%;
        padding: 10pt 30pt;
      }
    }
    &-header {
      position: relative;
    }
    &-logo {
      margin-bottom: 50px;
      margin-inline-start: -10px;
    }
    &-items {
      margin: 0 -40px;
      padding: 0 10px;
    }
    &-item {
      --color-text: gray;
      color: var(--color-text);
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 3px 20px;
      position: relative;
      margin-bottom: 20px;
      width: 100%;
      &-icon {
        margin-inline-end: 15px;
        line-height: $icon-size;
        display: flex;
      }
      &-label {
        line-height: $icon-size;
        font-size: 1.1em;
      }
      &-arrow {
        position: absolute;
        right: 30px;
      }
      &.active {
        --color-text: black;
        font-weight: 600;
        background-color: #F5F5F5;
        border-radius: 10px;
        // .icon {
        //   background: #9B9B9B;
        // }
        &::after {
          opacity: 1;
        }
      }
      // &:hover{
      //   opacity: 0.7;
      //   filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
      // }
      // &:disabled{
      //   color: $gray;
      //   cursor: auto;
      //   &:hover{
      //     opacity: 1;
      //     filter:unset
      //   }
      //   .icon{
      //     background: $gray;
      //   }
      // }

      @media (max-width: $mobile) {
        background: var(--background);
        //margin: 10px 30px;
        border-radius: 20px;
        padding: 20px;
      }
    }
    &-subitem {
      display: flex;
    }
    &-logout {
      @media (max-width: $mobile) {
        background: none;
      }
    }
    &-footer {
      margin: auto -40px 0;
      .logout {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }
    &-trigger {
      position: absolute;
      top: 30px;
      right: 30px;

    }
  }
  &-content {
    flex: 1 1 auto;
    @media (max-width: $mobile) {
      background: var(--background);
    }
  }
  &-logo {
    position: absolute;
    top: 30px;
    left: 30px;
  }
  @media (max-width: $mobile) {
    padding-top: 105px;
    //background: var(--background-secondary);
    box-sizing: border-box;
    &:has(.chatbot) {
      padding: 0;
    }
  }
}

.avatar {
  &-menu {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    @media (max-width: $mobile) {
      border-radius: 20px;
    }
  }
  &-profile {
    border-radius: 50%;
    --size: 150px;
    height: var(--size);
    width: var(--size);
    @media (max-width: $tablet) {
      --size: 128px;
    }
    @media (max-width: $mobile) {
      --size: 105px;
    }
  }
}

.features {
  margin:40px;
  .account-menu-item {
    --color-text: gray;
    font-size: 12px;
    padding: 3px 25px;
    margin-bottom: 0px;
    &.active{
      --color-text:var(--primary)
    }
    @media (max-width: $mobile) {
      margin-bottom: 5px;
    }
  }
  &-content{
    height: 100%;
    padding: 30px;
    iframe{
        height: 100%;
    }
  }
}

.dark {
  .account-menu-item,
  .logout {
    color: #a5a5a5;
  }
  .active {
    color: var(--text);
    .icon {
      background: $white;
    }
    &::after {
      background: $white;
    }
  }
}
.rtl {
  .account-menu-item::after {
    right: auto;
    left: -2px;
  }
  .account-menu-item-arrow {
    right: auto;
    left: 30px;
  }
}
.rtl-mobile {
  @media (max-width: $mobile) {
    direction: rtl;
    .account-menu-item::after {
      right: auto;
      left: -2px;
    }
    .account-menu-item-arrow {
      right: auto;
      left: 30px;
    }
    .icon {
      transform: scale(-1, 1);
    }
  }
}
