@import "../../styles/variables";

.node-preview {
  // border-radius: 20px;
  padding: 14px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &.onlyIcon{
    border:unset
  }
  &.market {
    position: relative;
    width: 205px;
    height: 60px;
  }
  &-label {
    //line-height: $icon-size;
    //margin-inline-start: 30px;
    margin-inline-end: auto;
    //color: var(--text);
  }
  @media (max-width: $mobile) {
    width: 200px;
  }
}

.dark {
  .node-preview {
    //background: var(--background-secondary);
    .icon-mask {
      background: $white;
    }
  }
}
