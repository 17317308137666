.config {
    display: flex;
    flex-direction: column;

    &-llm {
        .css-0 {
            margin: 0;
        }
    }

    &-filter {
        .chakra-collapse {
            overflow: unset !important;
        }

        .filter-group-add {
            margin: 0 auto
        }
    }

    .filter-group-name {
        border: none
    }

    .field-text {
        min-height: unset !important;
        max-height: unset !important;
        resize: none !important;
        overflow: hidden !important;
        height: 40px !important;
        background-color: #f8f8f8;
        line-height: 1rem !important;

    }

    .mde {
        border: none;
        border-radius: 10px;
        width: 100%;
    }

    .label {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: gray;
    }

    .text-field, .form-group > .form-control {
        border-radius: 8px;
        border: 1px solid  #E1E3E6 !important;
        background: #fff;
        width: 100%;
        padding: 1rem !important;
        height: 40px;
    }
}

.toolbar {
    display: none;
}