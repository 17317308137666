@import "../../styles/variables";
@import "../../styles/typo";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  --padding: 6px;
  padding: var(--padding) calc(var(--padding) * 3);
  line-height: $icon-size;
  border-radius: 15px;
  color: var(--color);
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  min-width: 100px;
  max-width: 326px;
  position: relative;
  display: inline-block;
  @media (max-width: $tablet) {
    //padding: 1rem 1.8rem;
    //max-width:unset;
  }
  &-primary {
    --bg-color: #{$primary};
    --color: #{$text-opposite};
    --border-color: var(--bg-color);
    &:hover,
    &.hover {
      --bg-color: #{$primary-active};
    }
    &:disabled,
    &.disabled {
      --border-color: $gray;
      cursor: not-allowed;
    }
  }
  &-black {
    --bg-color: #{$black};
    --color: #{$text-opposite};
    --border-color: var(--bg-color);
    &:hover,
    &.hover {
      opacity: 0.8;
    }
    &:disabled,
    &.disabled {
      --border-color: $gray;
      cursor: not-allowed;
    }
  }
  &-outline {
    --bg-color: transparent;
    --color: #{$primary};
    --border-color: var(--color);
    &:hover,
    &.hover {
      opacity: 0.8;
    }
  }
  &-white {
    --bg-color: #{$white};
    --color: #{$text};
    --border-color: var(--bg-color);
    &:disabled,
    &.disabled {
      --border-color: $gray;
      cursor: not-allowed;
    }
    &.loading {
      --bg-color: #{$light-gray};
    }
    &:hover,
    &.hover {
      opacity: 0.8;
    }
  }
  &-link {
    --bg-color: transparent;
    --color: #{$primary};
    --border-color: var(--bg-color);
    &:hover,
    &.hover {
      --color: #{$primary-active};
    }
  }
  &:disabled,
  &.disabled {
    --bg-color: transparent;
    background-color: #f8f8f8;
    color: $gray;
    --color: $gray;
    cursor: not-allowed;
    //pointer-events: none;
  }
  &-block {
    width: 100%;
    min-width: 0;
  }
  &-half-block {
    width: 50%;
    min-width: 0;
  }
  &-icon {
    //--bg-color: transparent;
    padding: var(--padding);
    min-width: 0;
    &:hover {
      opacity: 0.5;
      .icon {
        opacity: 0.5;
      }
    }
    &:disabled {
      opacity: 0.3;
      .icon {
        opacity: 0.3;
      }
      background-color: var(--bg-color);
    }
  }
  &-round {
    padding: var(--padding);
    border-radius: 50%;
    min-width: 0;
  }
  &-hidden {
    display: none;
  }
  &.loading {
    pointer-events: none;
    opacity: 0.6;
    position: relative;
    color: transparent;
    &::after {
      position: absolute;
      width: 13px;
      height: 13px;
      border: $white 3px solid;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  &-small {
    //font-size: 1.28rem;
    line-height: 20px;
    //font-weight: 600;
    --padding: 2px;
  }
  &-medium {
    font-size: 1.28rem;
    font-weight: 600;
    --padding: 15px;
  }
  &-large {
    font-size: 1.28rem;
    --padding: 25px;
    font-weight: 600;
  }
  &-center {
    display: block;
    margin: 0 auto;
  }
}

.dark {
  .button {
    --border-color: var(--bg-color);
    &-outline {
      --bg-color: #{$white};
    }
    &:disabled,
    &.disabled {
      --border-color: $gray;
      background-color: $gray;
      color: var(--text);
    }
  }
}
