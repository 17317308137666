.row {display: flex; flex-grow: 0; flex-wrap: wrap; margin: 0 -10px;}
.col {padding: 0 10px; box-sizing: border-box; min-width: 0;
  &-content {width: auto; flex: 0 0 auto;}
  &-stretch {width: auto; flex: 1 1 0;}
  &-5 {width: 5%; flex: 0 0 5%;}
  &-10 {width: 10%; flex: 0 0 10%;}
  &-15 {width: 15%; flex: 0 0 15%;}
  &-16 {width: 16.66%; flex: 0 0 16.66%;}
  &-20 {width: 20%; flex: 0 0 20%;}
  &-25 {width: 25%; flex: 0 0 25%;}
  &-30 {width: 30%; flex: 0 0 30%;}
  &-35 {width: 35%; flex: 0 0 35%;}
  &-33 {width: 33.33%; flex: 0 0 33.33%;}
  &-40 {width: 40%; flex: 0 0 40%;}
  &-45 {width: 45%; flex: 0 0 45%;}
  &-50 {width: 50%; flex: 0 0 50%;}
  &-60 {width: 60%; flex: 0 0 60%;}
  &-66 {width: 66.66%; flex: 0 0 66.66%;}
  &-70 {width: 70%; flex: 0 0 70%;}
  &-75 {width: 75%; flex: 0 0 75%;}
  &-80 {width: 80%; flex: 0 0 80%;}
  &-90 {width: 90%; flex: 0 0 90%;}
  @media (max-width: $tablet) {
    &-tablet {
      &-5 {width: 5%; flex: 0 0 5%;}
      &-10 {width: 10%; flex: 0 0 10%;}
      &-15 {width: 15%; flex: 0 0 15%;}
      &-16 {width: 16.66%; flex: 0 0 16.66%;}
      &-20 {width: 20%; flex: 0 0 20%;}
      &-25 {width: 25%; flex: 0 0 25%;}
      &-30 {width: 30%; flex: 0 0 30%;}
      &-33 {width: 33.33%; flex: 0 0 33.33%;}
      &-35 {width: 35%; flex: 0 0 35%;}
      &-40 {width: 40%; flex: 0 0 40%;}
      &-45 {width: 45%; flex: 0 0 45%;}
      &-50 {width: 50%; flex: 0 0 50%;}
      &-60 {width: 60%; flex: 0 0 60%;}
      &-66 {width: 66.66%; flex: 0 0 66.66%;}
      &-70 {width: 70%; flex: 0 0 70%;}
      &-75 {width: 75%; flex: 0 0 75%;}
      &-80 {width: 80%; flex: 0 0 80%;}
      &-90 {width: 90%; flex: 0 0 90%;}
      &-100 {width: 100%; flex: 0 0 100%;}
    }
  }
  @media (max-width: $mobile) { width: 100%; flex-basis: 100%;
    &-mobile {
      &-5 {width: 5%; flex: 0 0 5%;}
      &-10 {width: 10%; flex: 0 0 10%;}
      &-15 {width: 15%; flex: 0 0 15%;}
      &-16 {width: 16.66%; flex: 0 0 16.66%;}
      &-20 {width: 20%; flex: 0 0 20%;}
      &-25 {width: 25%; flex: 0 0 25%;}
      &-30 {width: 30%; flex: 0 0 30%;}
      &-33 {width: 33.33%; flex: 0 0 33.33%;}
      &-35 {width: 35%; flex: 0 0 35%;}
      &-40 {width: 40%; flex: 0 0 40%;}
      &-45 {width: 45%; flex: 0 0 45%;}
      &-50 {width: 50%; flex: 0 0 50%;}
      &-60 {width: 60%; flex: 0 0 60%;}
      &-66 {width: 66.66%; flex: 0 0 66.66%;}
      &-70 {width: 70%; flex: 0 0 70%;}
      &-75 {width: 75%; flex: 0 0 75%;}
      &-80 {width: 80%; flex: 0 0 80%;}
      &-90 {width: 90%; flex: 0 0 90%;}
    }
  }
}
