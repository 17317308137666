.search {
    &-box {
        position: absolute;
        background: #fff;
        top: 6rem;
        right: 1rem;
        display: flex;
        z-index: 5;
        max-width: 300px;
        border: 1px solid #ccc;
        border-radius: 10px;
        align-items: center;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        padding: 5px;
    }
    &-input {
        border:none;
        max-width: 160px;
        background: transparent;
    }
    &-count{
        position: relative;
        top: -1px;
        left: 3px;
        color: #999;
        font-size: 12px;
    }
    &-box-buttons {
        display: flex;
        position: relative;
        left: 8px;
        top: -2px;
        border-left:1px solid #ccc;
        :hover {
            opacity: 0.7;
        }
    }
}
