@import "../../styles/variables";
.agents {
    display: flex;
    flex-direction: column;
    .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: white;
        cursor: pointer;
        height: 75px;
        margin: 10px;
        padding: 15px;
        border-radius: 12px;
        border: var(--border-light);
        position: relative;
        &-icons {
            margin: 0 25px 0 10px;
        }
        &-title {
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 10px;
            line-height: 1;
            &-heading {
                max-width: 50vh;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        &-actions {
            display: flex;
            flex-wrap: wrap; /* Allow actions to wrap on smaller screens */
            gap: 10px;
            margin-left: auto;
            //margin-top: 10px;
            //width: 100%; /* Ensure actions take full width */
            .action {
                display: flex;
                font-size: 12px;
                color: $gray;
                &.delete {
                    margin-left: 100px;
                }
            }
        }
        &.active {
            border-color: #5b61eb;
            font-weight: 600;
        }
        &:not(.active):hover {
            filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
        }
    }
}

.agents-data > * {
    /* select all direct children of .agents-data */
    width: 60%;
    max-width: 700px; /* set to desired width */
}

.agents-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 25px;
    .header {
        display: flex;
        flex-direction: column;
        margin-top: 10pt;
        line-height: 50px;
        .title {
            &-1 {
                font-size: 36px;
                color: $primary;
            }
            &-2 {
                font-size: 16px;
                color: #9b9b9b;
            }
        }
        .img {
            display: flex;
            justify-content: center;
            position: relative;
            &:hover .close-button {
                display: flex;
            }
            .close-button {
                display: none;
                position: absolute;
                transform: translate(220%, -25%);
            }
        }
        .agent-name {
            font-weight: 600;
            font-size: 16px;
            input {
                outline: none;
                background-color: transparent;
                text-align: center;
            }
        }
    }
    .data-sources {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        .item {
            background-color: white;
            cursor: pointer;
            position: relative;
            border: var(--border-light);
            border-radius: 15px;
            display: flex;
            align-items: flex-start;
            padding: 12px 8px 4px;
            height: 50px;
            gap: 20px;
            width: 30.3%;
            min-width: 200px;

            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
            }
            &.active {
                border-color: $primary;
            }
        }
    }
    .agent-promt {
        &-textarea {
            width: 100%;
            resize: none;
            border-radius: 10px;
            //border: var(--border-light);
            padding: 10px;
            height: 100px;
        }
    }
}

.preview {
    &-header {
        display: flex;
        justify-content: space-between;
        .buttons-header {
            display: flex;
        }
        @media (max-width: $mobile) {
            display: none;
        }
    }

    &-iframe {
        &-content {
            @media (max-width: $mobile) {
                height: 80vh;
                iframe {
                    height: 100% !important;
                }
            }
        }
        .title {
            color: $primary;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 50%;
        }
    }
}

.btn-generate {
    font-weight: unset;
}

/* Styles for mobile devices */
@media (max-width: $mobile) {
    .agents {
        .item {
            height: 150px;
            &-title {
                font-size: 20px;
            }
            &-actions {
                gap: 0;
                .action.delete {
                    margin-left: 0;
                }
            }
        }
    }
    .agents-data {
        .agent-promt {
            &-textarea {
                border: var(--border-light);
            }
        }
    }

    .btn-generate {
        padding: 10px 20px;
        margin: 0 0 10pt;
    }

    .agents-data > * {
        width: 90%;
    }

    .agents-data .data-sources .item {
        width: 165px;
        min-width: unset;
        .css-11792ee {
            left: 130px;
        }
    }
}

// @media (max-width: $mobile) {
//     .css-1nbq4k6 {
//       padding: 0;
//       .css-qlig70 {
//         -webkit-padding-start: 0;
//         padding-inline-start: 0;
//         -webkit-padding-end: 0;
//         padding-inline-end: 0;
//         padding-top: 0;
//         padding-bottom: 0;
//       }
//     }
//   }
  