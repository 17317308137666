@import "../styles/variables";
@import "../styles/typo";

.menu-page {
  &-title {
    margin: 1vh 10vh;
    color: var(--text);
  }
  &-description {
    position: relative;
    top: 39px;
    color: var(--text);
    font-size: 16px;
    &.open {
      right: 340px;
      // &.rtl {
      //   right: 160px;
      // }
    }

    @media (max-width: $mobile) {
      top: 15px;
      padding: 10px;
    }
  }
  &-items {
    @extend.transpernt_over_flow;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    place-content: flex-start;
    @media (max-width: $mobile) {
      flex-flow: column;
      margin: 10px 30px;
      align-items: center;
      gap: 20px;
    }
    &.center {
      justify-content: center;
      @media (max-width: $mobile) {
        justify-content: flex-start;
      }
    }
    &.open {
      width: 51%;
    }
    &-box {
      --indent: 40px;
      --border-color: lightgray;
      max-width: 326px;
      max-height: 151px;
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 15px;
      padding: var(--indent);
      margin: 0 15px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      &.active {
        --border-color: #5b61eb;
        font-weight: 600;
        &::after {
          opacity: 1;
        }
      }
    }
    &-label {
      line-height: $icon-size;
      color: var(--text);
      display: inherit;
    }
    &-title {
      position: relative;
      bottom: 30px;
      font-weight: 600;
      margin: 0 auto;
      color: var(--text);
      font-size: 16px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
      @media (max-width: $mobile) {
        font-size: 1rem;
      }
      input {
        border: none;
        outline: none;
        background: none;
        text-align: center;
        max-width: 100px;
        bottom: 0px;
      }
    }
    &-actions {
      float: right;
      bottom: 38px;
      left: 60px;
      // position:absolute;
      // z-index:2;
      // pointer-events: none;
    }
    &-date {
      display: flex;
      flex-direction: column;
      margin-block: -10px;
      color: #a5a5a5;
    }
    &-status {
      position: relative;
      bottom: 36px;
      right: 60px;
    }
    &-subusers {
      display: inline-flex;
      width: 100%;
      align-items: center;
      & > * {
        margin: 10px;
      }
    }
    &-upload-file {
      display: flex;
      flex: 1;
      justify-content: center;
      label {
        cursor: pointer;
        height: 0;
        @media (max-width: $mobile) {
          height: auto;
        }
      }
      @media (max-width: $mobile) {
        flex: 0;
      }
    }
    &-form {
      flex: 1;
      .form-group {
        .form-control {
          background: $white;
        }
      }

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    &-team {
      flex: 1;
    }
    &-card {
      --border-color: $light-gray;
      width: 225px;
      height: 270px;
      cursor: pointer;
      margin: 15px;
      border: 1px solid var(--border-color) !important;
      @media (max-width: $mobile) {
        width: 300px;
      }
      .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &.active {
        --border-color: #5b61eb;
        font-weight: 600;
      }
      &:not(.active):hover {
        filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
      }
    }
  }
}

.builder-actions {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  margin-bottom: 2px;
  cursor: pointer;
  &.delete {
    color: red;
  }
}

.iframe-button-pricing {
  width: 200px;
  bottom: 80px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.iframe-plan {
  background-color: #5b61eb;
}
input[type="file"] {
  display: none;
  height: 0;
}

.pogressbar {
  --height: 3px;
  width: 140px;
  position: relative;
  top: 50px;
  @media (max-width: $mobile) {
    //top:40px;
  }
  &-container {
    background-color: #dbdbdb;
    border-radius: 15px;
    height: var(--height);
  }
  &-barCompleted {
    background-color: var(--primary);
    border-radius: 15px;
    height: var(--height);
    width: var(--width-completd);
  }
  &-label {
    position: relative;
    bottom: 30px;
    left: 110px;
    font-size: 1.2rem;
    font-family: Varela, Arial, sans-serif !important;
    color: var(--text);
  }
  &-label2 {
    position: absolute;
    top: 22px;
    color: var(--text);
    font-size: 1.2rem;
    @media (max-width: $mobile) {
      top: 12px;
    }
  }
  &-plan {
    position: absolute;
    top: -19px;
    color: var(--text);
  }
  &-button {
    position: absolute;
    top: -20px;
    left: 100px;
    color: var(--primary);
    cursor: pointer;
  }
}

.cards-new-chat {
  display: flex;
  margin: 10vh auto;
  .card {
    width: 348px;
    height: 423px;
    align-items: center;
    cursor: pointer;
    border-radius: 1rem;
    &:hover{
      filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
    }
  }
  .card-body {
    display: flex;
  }
}

.dark {
  .pogressbar-button {
    color: $gray;
  }
}
