.team-builder {
  height: 100%;
  //direction: ltr;
  cursor: grab;
}


ul.mde-suggestions {
    max-height: 150px;
    overflow: auto;
    border-radius: 0.375rem;
    z-index: 10;
    li {
      height: var(--indent);
      text-align: center;
      background-color: var(--background);
      &:hover{
        background-color: #f8f8f8;
        color: black;
      }
    }
    li[aria-selected="true"] {
      background-color: #f8f8f8;
      color: black;
    }
}
// .react-mde{
//   width: 100%;
// }