@import "../../styles/variables";

.dashboard {
    display: flex;
    //width: 1104px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 25px;
    flex-wrap: wrap;
    &-card {
        width: 350px;
        height: 345px;
        flex-shrink: 0;
        border-radius: 20px;
        &-header {
            display: flex;
            padding:1.5rem 2rem 0.5rem !important;
            &-title {
                /* Body Text/ Extra-Large/ Bold */
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px; /* 160% */
                letter-spacing: -0.4px;
            }
        }
        &-body {
            display: flex;
            flex-direction: column;
            align-items: self-start;
            gap: 20px;
            &-item {
                display: flex;
                align-items: center;
                &-icon {
                    margin-right: 15px;
                }
                &-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    &-title {
                        /* Body Text/ Small/ Bold */
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 171.429% */
                        letter-spacing: -0.28px;
                    }
                    &-subtitle {
                        color: $gray;
                        /* Body Text/ Extra-Small/ Medium */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 166.667% */
                        letter-spacing: -0.24px;
                    }
                }
            }
        }
        &-footer{
            justify-content: end;
            padding: 5px !important;
        }
        &.plan {
            height: 250px;
        }
        &:hover{
            filter:var(--filter-hover)
        }
    }
    @media (max-width: $mobile) {
        flex-flow: column;
        align-items: center;
    }
}

