@import "../../styles/variables";
@import "../../styles/typo";

.login_signup {
  @extend .transpernt_over_flow;
  background: var(--background-login);
  //background-image: linear-gradient(#d0d2f4, #d0d2f4);
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 100px;
  @media (max-width: $mobile) {
    padding: 0 1.5rem;
  }
  &-back {
    color: var(--text-login);
    font-size: 18px;
    line-height: $icon-size;
    display: inline-block;
    position: absolute;
    bottom: 20px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-inline-start: 45px;
    margin-inline-end: 45px;
    margin: 10px;

    .logo {
      margin-inline-start: 45px;
    }
    .lang {
      margin-inline-end: 45px;
    }
    @media (max-width: $mobile) {
      margin: 10px 0;
      .logo {
        margin-inline-start: 0;
      }
      .lang {
        margin-inline-end: 0;
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    .form-group {
      .form-control {
        line-height: 2rem;
      }
      .form-label{
        bottom: 40px;
      }
    }
  }
  &-box {
    max-width: 590px;
    width: 100%;
    //background: var(--background);
    border-radius: 30px;
    padding: 50px 130px;
    margin: auto;
    box-sizing: border-box;
    @media (max-width: $mobile) {
      padding: 30px 20px;
      background: inherit;
    }
    button {
      margin: 0 auto;
      line-height: 20px;
      margin-bottom: 5pt;
      min-height: 44px;
    }
  }
  &-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    color: var(--text);
    text-align: center;
    padding-bottom: 20pt;
  }
  &-description {
    display: flex;
    justify-content: center;
    color: var(--text);
    text-align: center;
    margin-bottom: 20px;
  }
  &-timer {
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }

  .partner-dropdown {
    width: 100%;
  }
}
