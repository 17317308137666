@import "../../../styles/variables";
@import "../../../styles/typo";

:root {
    --padding-area: 0;
}

@mixin message {
    position: relative;
    &-text {
        box-sizing: border-box;
        line-height: 1.25rem;
        position: relative;
        padding: var(--padding);
        margin: 1rem;
        min-height: 2.25rem;
        width: fit-content;
        border-radius: 0 10px 10px 10px;
        border: var(--border-light);
        max-width: 80%;
        list-style-position: inside;
        background: white; 
        color: black; 
        .typing-bubble {
            display: inline-block;
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.2rem;
            box-sizing: border-box;
            background: #ccc;
            border-radius: 50%;
            &.typing-1 {
                animation: typing 3s infinite;
            }
            &.typing-2 {
                animation: typing 3s 250ms infinite;
            }
            &.typing-3 {
                animation: typing 3s 500ms infinite;
            }
        }
        &.emptyText {
            background: transparent !important;
            border: 0 !important;
        }
        table {
            overflow: auto;
        }
        .time{
            color: lightgray;
            position: absolute;
            right: 5px;
            bottom: 0;
            font-size: 10px;
        }
    }
    &-elements {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -10px;
        padding: var(--padding-child);
        gap: 4px;
        min-width: 270px;
        max-width: 350px;
        flex: none;
        order: 1;
        flex-grow: 0;
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        button {
            padding: 10px 11px;
            background: #ffffff;
            border-radius: 10px;
            box-sizing: border-box;
            cursor: pointer;
            border: var(--border-light);
            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
            }
            &:disabled {
                filter: unset;
                cursor: not-allowed;
                color: $gray;
            }
        }
    }
    &-extra-element {
        padding: var(--padding-child);
    }
    &-media {
        border-radius: 15px;
        object-fit: contain;
        cursor: pointer;
        max-width: 150pt;
    }
    &.inbound {
        .message {
            &-text {
                margin: 1rem 1rem 1rem auto;
                border-radius: 10px 10px 0 10px;
                background-color: #5b61eb; 
                color: white; 
            }
            &-elements {
                &:has(.message-media) {
                    max-width: unset !important;
                }
            }
            &-media {
                margin: 1rem 1rem 1rem auto;
                //max-height: 100px;
            }

        }
        .mde-preview .mde-preview-content a{
            color:white;
            :hover{
                color: #4078c0;
                text-decoration: underline;
            }
        }
    }
    &.formInbound {
        display: none;
    }
}

.widget-content-chat {
    @extend.transpernt_over_flow;
    --padding: 0.2rem;
    --padding-child: 0 1rem;
    padding: 13pt 0pt 0pt 20pt;
    .message {
        @include message;
    }
    &.nonImage {
        padding: 0 !important;
    }
}

.live{
    .widget-content-chat .message-elements button:hover{
        filter:unset;
        cursor: auto;
    }

    .message-elements{
        .extra-element{
            display: none
        }
    }
}




.widget-content-form {
    @extend.transpernt_over_flow;
    --padding: 0.4rem;
    --padding-child: 0 1rem;
    padding: var(--padding);
    .message {
        @include message;
        .message {
            &-text {
                line-height: unset;
                margin: unset;
                min-height: unset;
                width: unset;
                max-width: unset;
                background: unset;
                border-radius: unset;
                border: unset;
                font-size: 1.2em;
                &.error {
                    color: red;
                    font-size: 1em;
                }
            }
            &-elements {
                max-width: unset;
                align-items: center;
                .buttons {
                    margin-top: 20px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: center;
                    gap: 10px;
                    button {
                        min-width: 11rem;
                    }
                }
            }
        }
    }
}

.rdp {
    margin: 0;
    --rdp-outline: 1px solid var(--primary);
    &-button {
        margin-right: 2px;
        margin-bottom: 2px;
    }
    &-dropdown_month {
        cursor: pointer;
    }
    &-dropdown_year {
        cursor: pointer;
    }
}

.file-uploader {
    border-color: white !important;
    min-width: unset !important;
    visibility: hidden;
    &.open {
        visibility: visible;
    }
}

@mixin mobile-preview {
    .widget-content-chat {
        padding: 20pt 0 0 30pt;
        .message {
            &-elements {
                max-width: 270px;
            }
        }
    }

    .widget-content-form {
        .message {
            &-text {
                font-size: 1.5em;
            }
            .buttons {
                margin-top: 0;
                flex-direction: column;
            }
        }
    }
}

@media (max-width: $mobile) {
    @include mobile-preview;
}

.preview {
    @include mobile-preview;
}

.widget-maxLength{
    font-size: 10px;
    bottom: 0;
    right: 10px;
    position: absolute;
    &.rtl{
        right: 0;
        left:10px;
    }
}