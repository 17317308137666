// @import '../../components/form/form.scss';

// .dropdown-method{
//     @extend .form-group;
//     @extend .form-control;
//     text-align: center;
//     padding: 1.5rem;
//     cursor: pointer;
    
// }

.css-1y0e7gb{
    position: relative;
    z-index: 5;
}