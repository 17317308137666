@import "/src/styles/variables";

.dropdown {
  position: relative;
  vertical-align: top;
  display: inline-block;
  font-size: 15px;
  &-items{
    &-list{
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-trigger {
    cursor: pointer;
    outline: none;
    border: 1px solid rgba($white, 0);
    display: block;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
    .disabled > & {
      pointer-events: none;
    }
  }
  &-content {
    position: absolute;
    top: 100%;
    left: -5px;
    background: $white;
    box-shadow: 0px 10px 50px rgba($primary, 0.15);
    opacity: 0;
    pointer-events: none;
    border-radius: 6px;
    transition: all 0.3s;
    padding: 10px;
    transform: scale(0.9, 0.7);
    transform-origin: 0 0;
    transition-timing-function: ease-out;
    //min-width: 150px;
    z-index: 100;
    &::before {
      position: absolute;
      content: "";
      top: -16px;
      left: 18px;
      border: transparent 8px solid;
      border-bottom-color: $white;
    }
    &-no-background {
      background: none;
      box-shadow: none;
      &::before {
        content: none;
      }
      .dropdown-item:hover {
        background: none;
      }
    }

  }
  &-item-wrapper {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    cursor: default;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  &-item {
    display: grid;
    padding: 0.6rem;
    white-space: nowrap;
    color: var(--text);
    box-sizing: border-box;
    width: 100%;
    max-width: 250px;
    text-decoration: none;
    position: relative;
    z-index: 100;
  }
  &-right .dropdown-content {
    right: -5px;
    left: auto;
    top:50px;
    transform-origin: 100% 0;
    &::before {
      left: auto;
      right: 13px;
    }
    @media (max-width:$mobile) {
      left: 0;
    }
  }
  &-open {
    .dropdown-content {
      margin-top: 10px;
      pointer-events: auto;
      opacity: 1;
      transform: scale(1, 1);
      transition-timing-function: cubic-bezier(0, 1.85, 0.32, 1);
      transition-duration: 0.4s;
      @media (min-width:$mobile) and (max-width:$tablet) {
        margin-top: 0;
      }
    }
  }
  &-scroll {
    width: 100%;
    //overflow: hidden;
    .dropdown-items {
      //width: calc(100% + 30px);
      overflow: hidden;
      overflow-y: auto;
      max-height: 220px;
      min-width: 150px; 

    }
    .dropdown-item {
      width: calc(100% - 15px);
      box-sizing: border-box;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &-builder .dropdown-content{
    top:0;
    left: -5px;
    padding: 16px 0;
    border-radius: 15px;
    width:112px;
    margin:0;
  }
}

.rtl {
  .dropdown {
    .dropdown-content {
      right: -5px;
      left: auto;
      transform-origin: 100% 0;
      &::before {
        left: auto;
        right: 13px;
      }
    }
    &-right .dropdown-content {
      right: auto;
      left: -5px;
      transform-origin: 0 0;
      &::before {
        left: 18px;
        right: auto;
      }
    }
  }
}



