@import './variables';

.text {
  font-size: 1rem;
  line-height: 2rem;
  &-small {
    font-size: 0.86rem;
    line-height: 2.57rem;
  }
  &-large {
    font-size: 1.28rem;
    line-height: 2.57rem;
  }
}

.title {
  font-size: 1.71rem;
  line-height: 4.57rem;
  font-weight: normal;
  &-large {
    font-size: 2.57rem;
    line-height: 4.57rem;
    font-weight: bold;
  }
}

.transpernt_over_flow {
  overflow: auto;
  @media (max-width:$mobile) {
    overflow-x: hidden;
  }
  
}
.transpernt_over_flow::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  display: none;
}


