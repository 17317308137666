@import "../../styles/variables";

.agent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 15px;
    //max-height: 60vh;
    //min-height: 50vh;
    //height: 50vh;
    height: 100%;
    @media (max-width: $mobile) {
        //min-height: unset;
        flex-grow: 1;
    }
    --gap: 10px;
    &-data {
        gap: 15px;
        .loaders-tabs {
            display: flex;
            flex-direction: row;
            gap: var(--gap);
            &-tab {
                border-radius: 8px;
                border: var(--border-light);
                background: #fff;
                justify-content: c;
                align-items: center;
                text-align: center;
                padding: 10px;
                width: 110px;
                justify-content: center;
                display: flex;
                cursor: pointer;
                &.active {
                    background: $primary;
                    color: $white;
                }
            }
        }
        .loader {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 60vh;
        }
        .url-loader {
            height: 100%;

            //max-height: var(--height);
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
            // min-height: var(--height);
            // gap: var(--gap);
            &-container {
                position: relative;
                display: flex;
                align-items: center;
            }
            &-input {
                padding-right: 80px;
                width: 100%;
                display: flex;
                height: 68px;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                flex-shrink: 0;
                border-radius: 15px;
                border: var(--border-light);
                background: $white;
            }
            &-button {
                position: absolute;
                right: 10px;
                width: 140px;
            }
        }
        .file-loader {
            height: 100%;
            .loader-list {
                height: 50%;
            }
        }
        .loader-list {
            //max-height: var(--height);
            overflow: auto;
            background: white;
            padding: 10px;
            border-radius: 15px;
            height: 80%;
        }
    }
    &-deatils {
        //flex-direction: column;
        //display: flex;
        align-items: center;
        gap: 32px;
        .form {
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            align-items: center;
            &-field {
                display: flex;
                flex-direction: column;
                gap: 8px;
                max-width: 410px;
                width: 100%;
                &-text {
                    display: flex;
                    height: 34px;
                    padding: 12px 16px;
                    align-items: center;
                    gap: 8px;
                    border-radius: 8px;
                    border: 1px solid #e1e3e6;
                    &.textarea {
                        height: 70px;
                    }
                }
                &-label {
                    color: #404d61;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    align-self: stretch;
                }
            }
        }
        .agent-stages {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .form-field {
                gap: 0;
                &-label {
                    font-size: 12px;
                }
                textarea {
                    min-height: 50px;
                }
            }
        }

        .img {
            display: flex;
            justify-content: center;
            position: relative;
            &:hover .close-button {
                display: flex;
            }
            .close-button {
                display: none;
                position: absolute;
                transform: translate(220%, -25%);
            }
        }
    }
    &-conditions {
        //gap: 10px;
        &-list {
            padding: 10px;
            overflow: auto;
            //max-height: var(--height);
            display: flex;
            flex-direction: column;
            gap: var(--gap);
        }

        &-container {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            border: 1px dashed #d8d8d8;
            background: #fff;
            position: relative;
        }
        &-btn {
            margin-top: 0;
            align-self: center;
        }
        .condition {
            &-part {
                flex: 1;
                padding: 12px 12px 5px;
                display: flex;
                flex-direction: row;
                &.else-part {
                    border-top: 1px dashed #d8d8d8;
                }
                &-remove {
                    position: absolute;
                    left: -21px;
                    top: -21px;
                }
            }
            &-header {
                display: flex;
                width: 90px;
                height: 35px;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid #e1e3e6;
                background: #fff;
                justify-content: center;
            }
            &-content {
                min-height: 50px;
                width: 100%;
            }
            &-textarea {
                width: 100%;
                height: 100%;
                padding: 10px;
                resize: none;
                border: 0;
                color: #737373;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
            }
        }
    }
    // .css-editor {
    //     background-color: wheat;
    // }
}

.preview-action {
    color: #a5a5a5;
    font-size: 12px;
}

.continue-button {
    margin-top: auto;
    align-self: center;
}

.uppy-Root {
    z-index: 0;
}

.uppy-Dashboard-files,
.uppy-Dashboard-innerWrap {
    background-color: white !important;
}

.uppy-DashboardContent-addMore {
    color: $primary !important;
}

.uppy-Dashboard-browse {
    color: $primary !important;
}

.uppy-Dashboard-Item {
    max-height: 80px !important;
    &-preview {
        max-height: 80px !important;
    }
}

.text-field{
    background-color: white !important;
    &::placeholder{
        color: black !important;
    }
}