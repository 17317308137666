@import "./variables";
@import "./typo";
@import "./fonts";
@import "./grid";

:root {
  --primary: #5b61eb;
  --error: #ee5959;
  --button: #5b61eb;
  --background-login: linear-gradient(#F6F2FF, #c5cbff, #F1F3FF);
  --background: #ffffff;
  --background-content: linear-gradient(111.95deg, #fff8f2 -0.91%, #f1f3ff 100%);

  --background-content-builder: linear-gradient(111.95deg, #ffffff -0.91%, #f1f3ff 100%);
  --background-content-team: linear-gradient(270deg, #FFF8F2, #F1F3FF);
  --background-hover: #f2f2f2;
  --background-secondary: #d9eaff;
  --text: #000000;
  --text-login: var(--primary);
  --text-opposite: #ffffff;
  --box: #ffffff;
  //--box-inactive: #f8f8f8;
  --box-inactive: #fff;
  --icon-color: #000000;
  --border: var(--primary);
  --border-light: 0.5px solid lightgray;
  --font-family-ltr: Poppins, Arial, sans-serif;
  --filter-hover: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5));
  .dark {
    --background-login: #5b61eb;
    --background: #272a73;
    --background-content: #272a73;
    --background-hover: #2f2f9f;
    --background-secondary: #5b61eb;
    --text: #ffffff;
    --text-login: #ffffff;
    --text-opposite: #000000;
    --box: rgba(#ffffff, 0);
    --box-inactive: #5b61eb;
    --icon-color: #ffffff;
    --border: #f8f8f8;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: $font-size;
  line-height: 2.57rem;
  color: var(--text);
  font-family: var(--font-family-ltr) !important;
  //min-height: 100vh;
  height: 100% !important; /* fallback for Js load */
  overflow: hidden !important ;
  -webkit-overflow-scrolling: touch !important;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
a,
button,
input,
select,
textarea {
  outline: none;
  margin: 0;
  padding: 0;
}

input[type="button"] {
  cursor: pointer;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
// p {
//   margin: 0 0 $padding-small;
//   @extend .text;
// }

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.only-desktop {
  @media (max-width: $tablet) {
    display: none !important;
  }
}

.tablet-desktop {
  display: none !important;
  @media (min-width: $mobile) {
    display: block !important;
  }
}

.only-tablet {
  @media (min-width: $tablet) {
    display: none !important;
  }
}

.only-mobile {
  display: none !important;
  @media (max-width: $mobile) {
    display: initial !important;
  }
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-header {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 35px 20px;
    &.min-height {
      min-height: 125px;
      @media (max-width: $mobile) {
        min-height: unset;
      }
    }
    @media (max-width: $mobile) {
      padding: 14px 25px;
    }
    &-title {
      color: var(--text);
      font-size: 2em;
      font-weight: bold;
      &.open {
        right: 350px;
      }
    }
    &-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      .content {
        display: flex;
        align-items: center;
      }
    }
    &-left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      .free-box {
        position: relative;
        top: 10px;
      }
    }
    .page-header .page-header-left .left {
      border-bottom: none;
    }
  }

  @media (max-width: $mobile) {
    .page-action ~ .page-action {
      margin-left: 0px;
    }
  }

  &-content {
    @extend.transpernt_over_flow;
    flex: 1 1 auto;
    overflow-x: hidden;
    text-align: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $mobile) {
      padding: 0 0 10px;
    }
    footer {
      margin-bottom: 5rem;
    }
  }
  .page-action ~ .page-action {
    margin-left: 10px;
  }
  &.chatbot {
    background: var(--background-content-builder);
  }
}

.link {
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
  color: $primary;
  transition: 0.3s;
  &:hover {
    color: $primary-active;
  }
}

.dark {
  .link {
    color: $lighten-light;
    text-decoration: underline;
  }
  .cover-box {
    background-color: unset;
    &.active {
      background-color: var(--box-inactive);
    }
  }
}

.rtl {
  direction: rtl;
  font-family: Varela, Arial, sans-serif !important;
  input,
  textarea {
    &::placeholder {
      direction: rtl;
    }
  }
  .page-actions {
    right: auto;
    left: 30px;
  }
}

.mde-header {
  direction: ltr;
  .icon {
    transform: scale(1, 1) !important;
  }
}

.mde-preview .mde-preview-content p{
  text-align: start;
  }

.markdown-code {
  //border-right: 4px solid rgb(77, 210, 239);
  //background: rgba(77, 210, 239, 0.1);
  background: #f5f5f5;
}

.cover-box {
  background-color: var(--box-inactive);
}

.tooltip {
  position: relative;
  display: inline-block;
  .tooltiptext {
    visibility: hidden;
    background-color: $gray;
    color: $white;
    text-align: center;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 100px;
    z-index: 1;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Lowered z-index */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background: rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
  /* Black w/ opacity */
}
.modal.no-blur {
  backdrop-filter: blur(0);
}

iframe {
  width: 100%;
  height: 560px;
  border-radius: 15px;
  /* Rounded corners */
  z-index: 999999999;
}

/* Chat button styles */
.chat {
  position: fixed;
  right: 10px;
  /* Position to the right */
  bottom: 10px;
  /* Position at the bottom */
}

.background {
  background-color: #5b61eb;
  border-radius: 50%;
  box-shadow:
    0 2.1px 1.3px rgba(0, 0, 0, 0.044),
    0 5.9px 4.2px rgba(0, 0, 0, 0.054),
    0 12.6px 9.5px rgba(0, 0, 0, 0.061),
    0 25px 20px rgba(0, 0, 0, 0.1);
  height: 80px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 80px;
}

.chat-bubble {
  cursor: pointer;
  position: relative;
}

.chat-bubble.iconlink {
  height: 50px;
}

.bubble {
  transform-origin: 48%;
  transition: transform 500ms cubic-bezier(0.17, 0.61, 0.54, 0.9);
}

.line {
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-width: 2.75;
  stroke-linecap: round;
  transition: stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 90;
  stroke-dashoffset: -20;
}

.line2 {
  stroke-dasharray: 67 87;
  stroke-dashoffset: -18;
}

.circle {
  fill: rgb(255, 255, 255);
  stroke: none;
  transform-origin: 50%;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.active .bubble {
  transform: translateX(25px) translateY(2px) rotate(45deg);
  /* Adjusted values */
}

.active .line1 {
  stroke-dashoffset: 21;
}

.active .line2 {
  stroke-dashoffset: 30;
}

.active .circle {
  transform: scale(0);
}

.css-wl0d9u {
  display: flex;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: auto;
  overscroll-behavior-y: none;
}

.css-1245jog {
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 0.375rem;
  background: #ffffff;
  color: inherit;
  z-index: 1400;
  padding: 1.25rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
}

.css-1245jog.center {
  flex-direction: column;
  position: relative;
  width: 80%;
  margin-top: 4rem;
  margin-bottom: 4rem;
  max-width: 72rem;
}

.css-1245jog.side {
  position: fixed;
  right: 20px;
  opacity: 0;
  animation: fadeInAndSlideInChat 0.5s forwards;
}

.css-qlig70 {
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1 1 0%;
}

.css-1ik4h6n {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--close-button-size);
  height: var(--close-button-size);
  border-radius: 0.375rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  --close-button-size: 2rem;
  font-size: 0.75rem;
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  cursor: pointer;
}

.css-1ik4h6n:hover {
  background: rgba(0, 0, 0, 0.06);
}

.onkibi {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: currentcolor;
  vertical-align: middle;
}

@keyframes fadeInAndSlideInChat {
  to {
    opacity: 1;
  }
}

@media (max-width: 1140px) {
  .css-1245jog {
    padding: 0.5rem;
    border-radius: 0;
    height: 100%;
  }

  .css-1245jog.center {
    position: fixed;
  }

  .css-1245jog.side {
    width: 100%;
    right: unset;
  }

  .css-qlig70 {
    padding: 0;
  }

  #iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $mobile) {
  .css-1nbq4k6 {
    .css-qlig70 {
      -webkit-padding-start: 0;
      padding-inline-start: 0;
      -webkit-padding-end: 0;
      padding-inline-end: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}


