.type {
    background: rgba(219, 219, 219, 0.24);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height:20px;
    &.active{
      background: rgba(57, 201, 63, 0.1);
    }
    &-text {
      color: #A6ACBE;
      font-size: 12px;
      &.active{
        color: #34a853;
      }
    }
  }

.page-actions-left .type{
    width: 70px;
    height:25px;
}