@import "../../styles/variables";

.node {
  --indent: 30px;
  border-radius: 20px;
  padding: var(--indent);
  width: 400px;
  box-sizing: border-box;
  position: relative;
  background: var(--box-inactive);
  border: 1px solid $light-gray;
  transition: 0.6s;

  &.search {
    border-color: var(--border);
  }
  &-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: grabbing;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-inline-start: -10px;
    align-items: baseline;
  }
  &-label {
    background: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 10px;
  }
  &-name {
    z-index: 2;
    input,
    label {
      // line-height: $icon-size;
      color: $gray;
      border: none;
      background: transparent;
      text-overflow: ellipsis;
    }
  }
  &-text {
    color: var(--text);
    &-maxLength {
      display: flex;
      justify-content: flex-end;
      color: #ccc;
    }
  }
  &-options {
    margin-top: var(--indent);
    &-add {
      position: relative;
      z-index: 2;
    }
  }
  &-option {
    position: relative;
    margin-right: calc(-1 * var(--indent));
    padding-right: var(--indent);
    color: var(--text);
    margin-bottom: calc(0.5 * var(--indent));

    &-obj {
      display: grid;
      .method{
        margin-bottom: 30px;
        height: 50px;
      }
    }
    &-input {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 100%;
      min-height: 50px;
      margin-bottom: 30px;
    }
    &-number {
      font-weight: bold;
    }
  }
  &-description {
    font-weight: bold;
    font-style: italic;
    color: var(--text);
    &-jump {
      font-weight: bold;
      display: flex;
      justify-content: center;
      font-size: large;
    }
  }
  .divider {
    //margin: 0 calc(var(--indent) * -1);
    margin: 0 calc(-1 * var(--indent));
  }
  &-api{
    .description{
      margin-top: 30px;
    }
    .type{
      height: 50px;
    }
  }
  &-walist {
    &-arrow {
      //text-align: center !important;
      z-index: 2;
      line-height: 2;
    }
    &-section-title {
      margin-top: 20px;
      border: none;
      color: #9b9b9b;
      position: relative;
      top: 2px;
      left: 6px;
      z-index: 2;
    }
    &-row {
      border-radius: 10px;
      background-color: #f8f8f8;
      margin-bottom: 30px;
      .node-option-input {
        margin: 0;
        border-radius: 0;
      }
      .bottom {
        border-bottom: 1px solid #ccc;
      }
    }
    &-add {
      position: relative;
      z-index: 2;
      margin-top: 20px;
    }
    .form-remove {
      top: -15px;
    }
  }

  &-actions {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(22px) translateY(-22px);
    z-index: 2;
    .node-action ~ .node-action {
      margin-inline-start: 15px;
    }
  }
  &-handle {
    height: 0;
    width: 0;
    z-index: 2;
    transform: translateX(50%) translateY(-50%);
    direction: ltr;
    &-action {
      background: $primary;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      height: 15px;
      width: 15px;
      display: block;
      border: 0;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;
    }
    // &-source {
    //   right: 0;
    // }
    &-target {
      left: 0;
      stroke-width: 4;
    }
    &-remove {
      //transform: translateX(-20%) translateY(calc(-100% - 3px));
      transform: translateX(-30%) translateY(-30%);
      //transform: translateX(-50%) translateY(calc(-50% - 3px));

      

    }
  }

  &-template {
    .input {
      position: relative;
      cursor: pointer;
      z-index: 3;
      .mde-header {
        height: 0;
        border-bottom: none;
      }
    }
  }
  &-text-input {
    border: none;
    outline: none;
    background: none;
    width: 100%;
    position: relative;
    z-index: 3;
    margin: 0 calc(-1 * var(--indent)) calc(-1 * var(--indent));
    padding: 0 var(--indent) var(--indent);
    color: var(--text);
    pointer-events: none;
    box-sizing: content-box;
    & p {
      line-height: 1.3;
    }
  }
  .react-mde {
    border: none;
  }
  .mde-header {
    background: none;
    //margin: 0 calc(var(--indent) * -1);
    margin: 0 calc(-1 * var(--indent));
    padding: 0 var(--indent);
    border: none;
    height: 0;
  }
  .mde-text,
  .mde-preview {
    min-height: 100px !important;
    height: auto !important;
    resize: none !important;
    overflow: overlay !important;
    word-wrap: break-word;
  }
  .mde-header-item {
    margin: 0 5px !important;
    & button {
      padding: 0 !important;
    }
  }
  .mde-header-group {
    padding: 0 0 10px;
  }
  &-selected {
    background: var(--box);
    box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.07);
    border-color: $white;
    .node-cover{
      cursor: no-drop;
    }
    & .node-text-input {
      pointer-events: all;
    }
    & .mde-header {
      height: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .react-mde-toolbar button[data-name="markDown"] {
    border-right: 4px solid rgb(77, 210, 239);
    background: rgba(77, 210, 239, 0.1);
  }


  



  ul.mde-suggestions {
    max-height: 150px;
    overflow: auto;
    border-radius: 0.375rem;
    li {
      height: var(--indent);
      text-align: center;
      background-color: var(--background);
      &:hover{
        background-color: #f8f8f8;
        color: black;
      }
    }
    li[aria-selected="true"] {
      background-color: #f8f8f8;
      color: black;
    }

  }
}



.jump-list {
  background-color: blue;
}

.context-menu-node {
  position: absolute;
}

.dark {
  .node {
    .node-name {
      color: var(--text);
    }
    .mde-header {
      border-bottom-color: $white;
    }
    .node-handle-action {
      background: $white;
    }
    .node-selected .node-handle-action {
      background: $primary;
    }
    .mde-text {
      background: none !important;
      color: $white;
    }
  }
  .react-flow__edge-path {
    stroke: $white !important;
    &:hover {
      stroke: $white !important;
    }
  }
  .animated > .react-flow__edge-path {
    stroke: $white !important;
  }
}

.rtl {
  .node {
    direction: rtl;
    .node-actions {
      right: auto;
      left: 0;
      transform: translateX(-22px) translateY(-22px);
    }
  }
}

.method {
  &-actions {
    margin-inline-start: auto;
    &-item {
      border: none;
      outline: none;
      //width: 120px;
      cursor: pointer;
      padding: 0.5rem 1rem;
      //box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
      background: #f8f8f8;
    }
  }
}

.react-flow__edge-path {
  stroke-width: 3 !important;
  stroke: rgb(0 0 0 / 8%) !important;
  &:hover {
    cursor: pointer;
  }
}

.animated > .react-flow__edge-path {
  stroke: $primary !important;
}

.react-flow__edge.animated path {
  stroke-dasharray: none;
}

.interactive {
  cursor: not-allowed;
}

.highlight {
  color: $primary !important;

}

.variable{
  display: inline-flex;
  // vertical-align: top;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 100%;
  font-weight: var(--chakra-fontWeights-medium);
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: var(--chakra-radii-md);
  min-height: var(--chakra-sizes-6);
  min-width: var(--chakra-sizes-8);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-3);
  padding-inline-start: var(--chakra-space-3);
  -webkit-padding-end: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  --badge-color: var(--chakra-colors-white);
  background: var(--badge-bg);
  color: var(--badge-color);
  margin-bottom: 5px;
  font-family: var(font-family-ltr);
  &.local{
    --badge-bg: var(--chakra-colors-primary-500);
  }
  &.global{
    --badge-bg: var(--chakra-colors-yellow-500);
  }
}

.chat-builder .react-flow__handle-right {
  background: $primary;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  //position: absolute;

  //transform: translateX(-50%) translateY(-50%);
  transform: translateX(30%) translateY(-50%);
}

.chat-builder .react-flow__handle-left {
  transform: translateX(30%) translateY(calc(-50% - 10px));
}

.chat-builder .react-flow__handle.connectable {
  cursor: pointer;
}

// .chat-builder .react-flow__handle.connecting .react-flow__handle-right {
//   animation: bounce 1600ms infinite ease-out;
// }

@keyframes bounce {
  0% {
    transform: translate(0, -50%) scale(1);
  }
  50% {
    transform: translate(0, -50%) scale(1.5);
  }
}
