@import "/src/styles/variables";
@import "/src/styles/typo";

.form {
  position: relative;
  &-channel-button {
    position: relative !important;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 45%;
    //margin-top: 35px;
    margin: 2rem 0;
  }
  &-group {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    &-error {
      & .form-control {
        border: 1px solid $error !important;
      }
    }
    & .form-error {
      position: absolute;
      top: 100%;
      line-height: 20px;
    }
    &.form-group-error {
      margin-bottom: 40px;
    }
    .dropdown-content {
      margin-top: -10px;
    }
    textarea{
      min-height: 200px;
      max-height: 500px;
      line-height: 1.5;
    }
  }
  &-control {
    @extend .text-large;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    background: $lighten-gray;
    //background: white;
    width: 100%;
    outline: none;
    color: var(--text);
    transition: all 0.3s;
    text-overflow: ellipsis;
    position: relative;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 3rem;
    &:disabled{
      cursor: not-allowed;
    }
    &-copy {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    &-maxLength {
      color: #ccc;
      font-size: 12px;
      position: absolute;
      right: 15px;
      bottom: 0;
    }
    &-icon {
      position: absolute;
      top: 2px;
      right: 15px;
    }
    .form-left-icon ~ & {
      padding-left: 40px;
    }
    .form-right-icon ~ & {
      padding-right: 40px;
    }
  }
  &-label {
    @extend .text-large;
    position: absolute;
    left: 0;
    bottom: 35px;
    color: $gray;
    transform-origin: 0 0;
    transition: all 0.3s;
    padding: 14px 15px;
    line-height: $icon-size;
    transform: translate3d(0, 18px, 0);
    pointer-events: none;
    white-space: nowrap;
    z-index: 1;
    font-size: 12px;
    .form-control.filled ~ &,
    .form-control:focus ~ & {
      transform: translate3d(0, 0, 0) scale(0.72);
      left: 8px;
    }
    &.textarea {
      bottom: 100px;
    }
  }
  &-remove {
    position: absolute;
    // right: 0;
    // transform: translateX(50%);
    right: auto;
    left: 0;
    transform: translateX(-85%);
    background: $white;
    z-index: 2;
  }
  &-error {
    color: $error;
    font-size: 14px;
  }
  &-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2px;
  }
  &-popup {
    box-shadow: 0px 10px 50px rgb(91 97 235 / 15%);
    position: absolute;
    left: 400px;
    background: #ffffff;
    width: 250px;
    padding: 10px;
    border-radius: 15px;
    .form-group {
      margin-bottom: 10px;
    }
    &-field {
      .form-control {
        text-align: center;
      }
    }
  }
  &-phone {
    &.gray {
      .PhoneInputInput{
        background-color: #f8f8f8;

      }
    }
  }
}

input[type="text"]:disabled::-webkit-input-placeholder {
  color: black;
}

.dark {
  .form-control {
    background: var(--background-secondary);
    &::placeholder {
      color: var(--text);
    }
  }
}

.rtl {
  // .form-remove {
  //   right: auto;
  //   left: 0;
  //   transform: translateX(-50%);
  // }
  .form-label {
    left: auto;
    right: 0;
  }
  .form-control {
    font-family: Varela, Arial, sans-serif !important;
    &-copy {
      left: 10px;
      right: auto;
    }
    &-maxLength {
      left: 15px;
      right: auto;
    }
    &-icon {
      left: 15px;
      right: auto;
    }
  }
}

.realtive-icon-input {
  &.form-group {
    background: $lighten-gray;
    justify-content: center;
  }
  &.form-control {
    width: unset;
    &.empty {
      width: 120px;
    }
  }
  &.form-control-icon {
    position: relative;
    line-height: 2.5em;
  }
}
