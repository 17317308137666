/* Colors */
$black: #000000;
$white: #ffffff;

$lighten-light: #d9eaff;
$light-blue: #a8c7f5;
$blue: rgba(109, 171, 255, 0.3);
$dark-blue: #7176f7;
$darken-blue: #5b61eb;
$red: #ee5959;
$lighten-gray: #f8f8f8;
$light-gray: #d9d9d9;
$gray: #a5a5a5;

$primary: $darken-blue;
$primary-active: $dark-blue;

$text: $black;
$text-opposite: $white;
$secondary-text: $gray;
$active-text: $light-blue;
$link: $light-blue;

$border: $light-blue;

$error: $red;

$background: $lighten-gray;
$menu-background: $lighten-light;
$secondary-background: $light-gray;

/* Breakpoints */
$tablet: 1353px;
$mobile: 1140px;

$small-display:864px;


$icon-size: 30px;
$font-size: 14px;

$padding: 30px;
$padding-small: 20px;

$logo-channel-size: 60px;
$logo-channel-size-mobile: 30px;



