@use "sass:math";
@import "../../styles/variables.scss";



.plans {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: $mobile) {
        flex-flow: column;
        align-items: center;
    }
    .plan {
        box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 6px 20px rgb(0 0 0 / 15%);
        border-radius: 15px;
        background: #fff;
        min-width: 300px;
        min-height: 450px;
        text-align: center;
        position: relative;
        margin: 10px;
        padding: 30px;
        &.active {
            border: 1px solid #5b61eb;
            .plan-title {
                color: #5b61eb;
            }
        }
        &-update-card {
            position: absolute;
            top: -40px;
            right: 0;
            cursor: pointer;
            &.rtl {
                left: 0;
                right: auto;
            }
        }
        &-header {
            background: #f8f8f8;
            margin: 10px -10px;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            border-radius: 15px;
        }
        &-title {
            font-size: 1.2rem;
            font-weight: 600;
            color: #000;
        }
        &-price {
            font-size: 1.2rem;
            color: #000;
        }
        &-content {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            &-feature {
                display: flex;
                & > * {
                    margin-left: 5px;
                    margin-bottom: 5px;
                }
            }
        }
        &-button {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
        }
    }
}

.price-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
        border-radius: 16px;
        background: #fff;
        border: 1px solid #000;
        color: #000;
        font-size: 1.2rem;
        font-weight: 600;
        min-width: 200px;
        min-height: 50px;
        margin: 10px;
        cursor: pointer;
        &:hover {
            background: #000;
            color: #fff;
        }
        &.active {
            border-color: blue;
        }
    }
}

.price-select {
    background: var(--background-secondary);
    border-radius: 30px;
    overflow: hidden;
    width: 240px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    color: #155fff;
    position: relative;
    padding-right: 120px;
    position: relative;
    div {
        color: black;
        font-size: 16px;
        &.active {
            //border: 1px solid #5b61eb;
            //border-radius: 30px
            background-color: #5b61eb;
            color: white;
            border-radius: 30px;
        }
    }
}

.switch-button {
    background: rgb(229 231 235 / 1);
    border-radius: 0.75rem;
    border-width: 4px;
    overflow: hidden;
    width: 100px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    color: #000;
    position: relative;
    padding-right: 120px;
    margin: 20px auto;
    &-second {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-checkbox {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;

        &:checked + .switch-button-label:before {
            transform: translateX(0);
            transition: transform 300ms linear;
        }

        & + .switch-button-label {
            position: relative;
            padding: 10px 0;
            display: block;
            user-select: none;
            pointer-events: none;

            &:before {
                content: "";
                background: #fff;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 0.75rem;
                border-width: 4px;
                transform: translateX(120px);
                transition: transform 300ms;
            }

            .switch-button-label-span {
                position: relative;
            }
        }
    }
}

.card {
    display: flex;
    margin: 10px 30px;
    align-items: flex-start;
    flex-direction: column;
    & > * {
        margin-left: 10px;
    }
    &-deatils {
        & > * {
            margin-left: 5px;
        }
        &-name {
            position: relative;
            top: 7px;
        }
    }
}

.subsricption {
    width: 130px;
    height: 30px;
    border-radius: 0.75rem;
    border-width: 4px;
    background: rgb(229 231 235 / 1);
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid var(--background);
    pointer-events: all;
    transition: all 0.3s;
    margin: 0 auto;
    font-size: 14px;
    &-circle {
        position: absolute;
        background: #fff;
        width: 50px;
        height: 22px;
        top: 0;
        right: 3px;
        bottom: 0;
        transition: all 0.3s;
        border-radius: 0.5rem;
        margin: auto 0;
    }
    &-select {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 1;
        // &-monthly {
        //     //left: 50px;
        // }
        // &-yearly {
        //     //right: 40px;
        // }
    }
}

.active {
    .subsricption-circle {
        right: 76px;
    }
    // .subsricption-night {
    //   .icon {
    //     background: var(--background);
    //   }
    // }
}

@mixin planStyle {
    border: 1px solid var(--primary);
    .eapps-pricing-table-column-button-container {
        display: none;
    }
}

@mixin planDisableStyle {
    .eapps-pricing-table-column-button-container {
        display: none;
    }
    #eapps-pricing-table-1 .eapps-pricing-table-column-3 {
        box-shadow: unset !important;
    }
    .eapps-pricing-table-column-featured {
        box-shadow: unset !important;
        margin: 0 !important;
    }
}

.FREE {
    .eapps-pricing-table-column-1 {
        @include planStyle;
    }
}

.BASIC {
    @include planDisableStyle;
    .eapps-pricing-table-column-2 {
        @include planStyle;
    }
}
.PREMIUM {
    @include planDisableStyle;
    .eapps-pricing-table-column-3 {
        @include planStyle;
    }
}
.VIP {
    @include planDisableStyle;
}

.PriceColumn-priceAndButton {
    .Button .PriceColumn-button .Button--primary .Button--lg {
        font-size: 18px;
    }
}

.hide-subscribe-button .subscribe-button-selector {
    display: none;
}
