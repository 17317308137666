.team {
  &-node {
    border-radius: 500px;
    position: relative;
    //background-color: #fff;
    
    
    background-image: url("https://officelyfiles.s3.eu-west-1.amazonaws.com/2adc543c-9491-4b95-8f4c-afb28d4e1921.png");
    background-size: cover;
    height:120px;
    width:120px;


    background-position: center;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 12px;
    //width: 160px;

    text-align: center;
    font-weight: bold;
    color: #393939;
    cursor: pointer;
    line-height: 1.2;
    position: relative;

    &-workflow {
      border: 1px solid #0984e3;
      

      &-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5pt;
        color: black;
      }
    }

    &-helper {
      border: 1px solid red;

    }
    &.functions{
      border:none;
      box-shadow:none;
    }



  }

}



.handle {
  visibility: hidden;
}

.placeholder {
  width: 160px;
  background: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  box-shadow: none;
  cursor: pointer;
}