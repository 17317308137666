@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.woff) format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-SemiBold.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-SemiBold.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-SemiBold.woff) format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Bold.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Bold.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Bold.woff) format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Poppins"), url(../assets/fonts/Poppins-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Varela";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-regular-fm.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-regular-fm.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-regular-fm.ttf) format("truetype");
}
@font-face {
  font-family: "Varela";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-regular-fm.woff) format("woff");
}
@font-face {
  font-family: "Varela";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-regular-fm.woff2) format("woff2");
}

@font-face {
  font-family: "Varela";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-medium-fm.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-medium-fm.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-medium-fm.ttf) format("truetype");
}
@font-face {
  font-family: "Varela";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-medium-fm.woff) format("woff");
}
@font-face {
  font-family: "Varela";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-medium-fm.woff2) format("woff2");
}

@font-face {
  font-family: "Varela";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-bold-fm.eot) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-bold-fm.eot?#iefix) format("embedded-opentype");
}
@font-face {
  font-family: "Varela";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-bold-fm.ttf) format("truetype");
}
@font-face {
  font-family: "Varela";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-bold-fm.woff) format("woff");
}
@font-face {
  font-family: "Varela";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: local("Varela"), url(../assets/fonts/varela-extra-bold-fm.woff2) format("woff2");
}
