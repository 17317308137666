@import "../../styles/variables";
@import "../../styles/typo";

.widget {
    @extend.transpernt_over_flow;
    height: 100%;
    position: relative;
    //--background-widget: white;
    //background-color: var(--background-widget);
    &-header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0.9em 15% 0.8em;
        //background-color: var(--background-widget);
        border-radius: 6px;
        .button {
            border-radius: 6px;
            min-width: 57px;
        }
        // &-actions {
        //     flex:1;
        // }
        &-logo {
            flex:1;
            .widget-logo {
                object-fit: contain;
                width: 80px;
                height: 40px;
            }
        }
    }
    &.form {
        .widget {
            &-content {
                height: unset;
                margin: 0 45vh;
            }
        }
    }
    &-footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        padding:0 1rem;
        .answer-input {
            display: flex;
            position: relative;
            align-items: center;
            background: #ffffff;
            border-radius: 8px;
            border: var(--border-light);
            min-height: 4rem;
            width: 100%;
            box-shadow: 0 4px 4px 0 rgba(155, 155, 155, 0.4);
            .f-enter {
                position: absolute;
                bottom: -1rem;
                transform: translateY(-50%);
                right: 0;
                &.disabled {
                    .button {
                        opacity: 0.3;
                    }
                }
            }
            &.rtl {
                .f-enter {
                    right: unset;
                    left: 0;
                }
                .icon {
                    transform: scale(-1, 1);
                }
            }
        }
        .scroll-btn {
            //margin: -4rem 0rem 2rem 18rem;
            z-index: 1;
            right: 0;
            top: -3rem;
            transform: translateY(-50%);
            position: absolute;
        }
        textarea {
            border: 0;
            padding: 0 2rem;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
            font-family: Poppins, Arial, sans-serif;
            width: 100%;
            resize: none;
            max-height: 25rem;
            &.rtl {
                font-family: Varela, Arial, sans-serif !important;
            }
            @media (max-width: $mobile) {
                font-size: 2.72em;
            }
        }
        .powerd-by-officely {
            display: none;
        }
        &.free {
            margin-bottom: 0;
            .powerd-by-officely {
                display: block;
                width: 150px;
            }
        }
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 25vw;
        margin-top: 5vh;
        height: 90%;
        border-radius: 15px;
    }
    &.chat {
        background-color: var(--background-secondary);
    }
    &.AI {
        background-color: white;
        .widget {
            &-content {
                margin-top: 10vh;
                position: relative;
                .stop-btn {
                    position: absolute;
                    bottom: 85pt;
                    right: 10pt;
                }
                .total-price-chat {
                    position: absolute;
                    bottom: 17px;
                    left: 23px;
                    font-weight: 600;
                }
            }
            &-header {
                background-color: white;
                display: none;
            }
        }
    }
    .react-mde {
        border: none;
    }
    .mde-header {
        display: none;
    }
    .mde-preview {
        word-wrap: break-word;
    }
    .think{
        display: none;
    }


}

.animate {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    & > * {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &.fade-in {
        animation-name: fadeIn;
    }
    &.fade-in-down {
        animation-name: fadeInDown;
    }
    &.fade-in-up {
        animation-name: fadeInUp;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -12px, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 12px, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@mixin mobile-preview {
    .widget {
        &-header {
            padding: 0.9em 2% 0.8em;
            border-radius: 0;
            &-logo {
                margin-inline-start: 10px;
            }
        }
        &-footer {
            textarea {
                font-size: 16px;
                line-height: 1.5rem;
            }
        }
        &-content {
            background: inherit;
        }

    }


    .vff {
        padding: 0 7px;
        margin: 5vh 0 0;
        .fh2 {
            font-size: 1.5em;
            padding-right: 0;
            display: flex;
            flex-direction: column;
        }
        .mde-preview .mde-preview-content {
            padding: 0;
        }

        &-color {
            position: absolute;
            left: 7rem;
            visibility: hidden;
            z-index: 5;
            &.open {
                visibility: visible;
            }
        }
    }

    ul.f-radios li {
        font-size: 1em !important;
        padding: 0.6em 0.68em;
    }

    .widget .vff {
        padding: 0 5%;
    }
    .f {
        &-answer {
            position: absolute;
            bottom: 1rem;
        }
    }

    .media {
        width: 19em;
        height: unset;
    }
}

@media (max-width: $mobile) {
    @include mobile-preview;
    .widget {
        display: flex;
        flex-direction: column;
        &-content {
            margin: unset !important;
            flex-grow: 1;
        }
    }
}

.preview {
    @include mobile-preview;
    .widget {
        &-content {
            flex-grow: 1;
            height: 0%;
            margin: unset !important;

            //padding: unset;
        }
    }
}

@keyframes typing {
    0%,
    75%,
    100% {
        transform: translate(0, 0.25rem) scale(0.9);
        opacity: 0.5;
    }

    25% {
        transform: translate(0, -0.25rem) scale(1);
        opacity: 1;
    }
}
